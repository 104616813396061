<template>
    <modal
        :okAction="ok"
        :title="title"
        :id="id"
        size="sm"
        :actionButtons="true"
        :closeButton="false"
    >{{$t(text || 'Do you really want to delete the record ?')}}</modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
    name: 'confirmationModal',
    props: ['id', 'confirmAction', 'item', 'item', 'text'],
    components: {
        Modal,
    },
    data() {
        return {
            hideForm: true,
            title: this.$t('Are you sure?'),
        }
    },
    created() {
        this.$root.$on('bv::hide::modal', (modal) => {
            this.hideForm = false;
        })
    },

    methods: {
        ok() {
            this.confirmAction(this.item);
            this.$root.$emit('bv::hide::modal', 'confirmationModal');
        }
    },

}
</script>

<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar m-0"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="#" class="navbar-brand">Dashboard</a>
                        </li>
                        <li
                            class="breadcrumb-item active d-flex justify-content-center align-items-center"
                            aria-current="page"
                        >
                            Theme Builder
                        </li>
                    </ol>
                </nav>
            </div>
        </nav>

        <div class="container">

            <div class="header">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                        <h6 class="header-pretitle">
                            Create New Theme
                        </h6>
                        <h1 class="header-title">
                            Themes
                        </h1>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary btn-sm" @click="$router.push({ name: 'builder-create'})">Add New</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-6 col-12" v-for="(theme, index) in themes" :key="index">
                    <div class="card">
                        <div class="card-body p-0 m-auto">
                            <img class="w-100" v-if="theme.coverImage" :src="theme.coverImage.preview" alt="">
                            <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 225px;">
                              <img src="@/assets/source/no-image.svg">
                            </div>
                        </div>
                        <div class="card-footer p-2">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto">
                                    <small>
                                        <span :class="[ theme.isPublic ? 'text-success' : 'text-danger']">●</span> 
                                        {{ theme.name }}
                                    </small>
                                </div>
                                <div class="col-auto">
                                    <button @click="$router.push({ name: 'builder-edit', params: { themeId: theme._id } })" class="btn btn-sm btn-white mr-2">
                                        <span class="fe fe-edit"></span>
                                    </button>
                                    <button class="btn btn-sm btn-white" @click="openModal({id:'confirmationModal', model:theme})">
                                        <span class="fe fe-trash-2 text-danger"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <confirmation-modal id="confirmationModal" :item="model" :confirmAction="deleteTheme"></confirmation-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/ButtonLoading";
import { modalMixins } from '@/mixins/modal.js'
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
    mixins: [modalMixins],
    components: {
        ButtonLoading,
        ConfirmationModal
    },

    data() {
        return {
            model: {},
            params: {
                query: {
                    $limit: 1000,
                    $sort: { createdAt: -1 },
                },
            }
        };
    },
    computed: {
        ...mapState("auth", {
            user: "payload",
        }),
        ...mapState('themes', {
            areThemesLoading: 'isFindPending',
        }),
        ...mapGetters('themes', {
            findThemesInStore: 'find',
        }),
        themes() {
            return this.findThemesInStore(this.params).data;
        },
        
    },
    created() {
        this.find(this.params);
    },

    methods: {
        ...mapActions("themes", ['create', 'find', 'patch', 'remove']),
        deleteTheme() {
            this.remove(this.model._id).then(result => {
                this.$toasted.global.success({
                    message: this.$t('Theme has been deleted')
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>

</style>